import React from "react"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"

import Privacy from "../../components/sections/privacy"
import Footer from "../../components/sections/footer"
import GetStarted from "../../components/sections/getstarted"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <Navigation />
    <Privacy />
    <GetStarted />
    <Footer />
  </Layout>
)

export default PrivacyPage
